import { AIPDATA_BASE_URL} from '../../ApiConfig';

export async function fetchEvents(productId: string, token: string) {
    try {
        const response = await fetch(`${AIPDATA_BASE_URL}/v2/event-definitions?productId=${productId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    } catch (error) {
        throw error;
    }
}