import { FLOW_SERVICE_BASE_URL, FLOW_HEADERS } from '../../ApiConfig'

export async function fetchTemplate(queryParam: string, token: string) {
    try {
        const response = await fetch(`${FLOW_SERVICE_BASE_URL}templates/check${queryParam}`, {
            method: 'GET',
            headers:  {
                'Authorization': `Bearer ${token}`,
                'x-aptean-tenant': FLOW_HEADERS['x-aptean-tenant']
            }
        });
        return response.json();
    } catch (error) {
        throw error;
    }
}

export async function fetchTemplateFile(templateid: string, sourceSchema: string, targetSchema: string, token: string) {
    try {
        const response = await fetch(`${FLOW_SERVICE_BASE_URL}templates/${templateid}`, {
            method: 'POST',
            headers:  {
                'Authorization': `Bearer ${token}`,
                'content-type': 'application/json',
                'x-aptean-tenant': FLOW_HEADERS['x-aptean-tenant']
            },
            body: JSON.stringify({
                sourceSchema: sourceSchema,
                targetSchema: targetSchema
            })
        });
        return response.blob();
    } catch (error) {
        throw error;
    }
}

export async function saveTemplate(formData: FormData, token: string, queryParam?: string) {
    try {
        const response = await fetch(`${FLOW_SERVICE_BASE_URL}templates${queryParam}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-aptean-tenant': FLOW_HEADERS['x-aptean-tenant']
            },
            body: formData
        });
        return response.json();
    } catch (error) {
        throw error;
    }
}

export async function transformData(formData: any, token: string) {
    try {
        const response = await fetch(`${FLOW_SERVICE_BASE_URL}transformation/preview`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'x-aptean-tenant': FLOW_HEADERS['x-aptean-tenant'],
                'x-aptean-correlation-id': '19492c71-f04b-438e-9bb9-850757d404aa',
                'x-aptean-user': 'aip-systemadmin'
            },
            body: JSON.stringify(formData)
        });
        return response.json();
    } catch (error) {
        throw error;
    }
}



export async function fetchAudit(token: string) {
    try {
        const response = await fetch(`${FLOW_SERVICE_BASE_URL}audits`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-aptean-tenant': FLOW_HEADERS['x-aptean-tenant']
            },
        });
        return response.json();
    } catch (error) {
        throw error;
    }
}