import { Button, Flex, FlexItem, FormGroup, Modal, Page, PageSection, Spinner, TextArea, ToolbarContent, ToolbarItem } from '@patternfly/react-core';  
import React from 'react';  
import { saveTemplate } from './FlowService';
import { getCurrentADMArchive } from './utils';
  
export function LookupConfigModal({ isModal, handleModalToggle, templateId, templateData, token, postSaveProcess }: { isModal: boolean, handleModalToggle: () => void, templateId: string, templateData: any, token: string, postSaveProcess: any}) {  
  
    return (  
        <React.Fragment>  
            <Modal  
                width={'75%'}  
                title="Lookup Configuration"  
                isOpen={isModal}  
                onClose={handleModalToggle}  
                height={'50%'}  
            >  
                <LookupConfig templateId={templateId} data={templateData} token={token} postSaveProcess={postSaveProcess}/>  
            </Modal>  
        </React.Fragment>  
    );  
}  

const emptyGuid = '00000000-0000-0000-0000-000000000000';
    
const saveFile = (templateId: string, templateData: any, token: string, postSaveProcess: any, payload: any) => {
    return getCurrentADMArchive().then(async (value: Uint8Array | null) => {
        
        let tenantId = templateData?.tenantId;

        console.log('File data: ', value);
        if (value === null) {
            return;
        }
        const fileContent = new Blob([value], {
            type: 'application/octet-stream'
        });
        const formData = new FormData();
        let queryParam = '';
        // If tenantId is default or empty or undefined or mappingType is CUSTOM, then need to pass templateId in query param i.e create new template
        if( tenantId === emptyGuid || tenantId === '' || tenantId === undefined || templateData?.mappingType === 'CUSTOM'){  
            queryParam = templateId ? `?mappingTemplateId=${templateId}` : '';  
        }
                
        formData.append('mappingTemplateInfo', JSON.stringify({
            'name': templateData?.templateName,
            "sourceId": templateData?.sourceId,
            "sourceName": templateData?.sourceName,
            'sourceEventType': templateData?.sourceEventType,
            "sourceVersion": templateData?.sourceVersion,
            "targetId": templateData?.targetId,
            "targetName": templateData?.targetName,
            "targetVersion": templateData?.targetVersion,
            'targetEventType': templateData?.targetEventType,
            'lookupConfig': payload
        }));
        formData.append('mappingTemplateFile', fileContent, templateData?.sourceName);
        return saveTemplate(formData, token, queryParam)
            .then(item => {
                console.log(item);
                postSaveProcess(item);
            }).catch(error => {  
                console.error('An error occurred when saving template ', error);  
            });
    })
}

export function LookupConfig({ templateId, data , token, postSaveProcess}: {templateId: string, data: any , token: string, postSaveProcess: any}) {  
    const [isLoading, setIsLoading] = React.useState(false);  
    const [payload, setPayload] = React.useState(data.lookupConfig);
  
    return (  
        <React.Fragment>  
            {isLoading ? <Spinner aria-label="Loading..." /> :  
                <Page>  
                    <PageSection padding={{ default: 'noPadding' }} variant='light'>  
                        <Flex>  
                            <FlexItem align={{ default: 'alignRight' }}>  
                                <ToolbarContent alignment={{ default: 'alignRight' }}>  
                                    <ToolbarItem>  
                                        <Button isDisabled={!templateId} onClick={async () => {  
                                            setIsLoading(true);  
                                            const processedPayload = payload.trim() === '' ? null : payload;
                                            await saveFile(templateId, data, token, postSaveProcess, processedPayload);
                                            setIsLoading(false);  
                                        }}>Save</Button>  
                                    </ToolbarItem>  
                                </ToolbarContent>  
                            </FlexItem>  
                        </Flex>  
                    </PageSection>  
                    <PageSection isFilled padding={{ default: 'noPadding' }} variant='light'>  
                        <Flex>  
                            <FlexItem style={{ flex: 'auto' }}>  
                                <FormGroup label="Configuration" fieldId='config-textarea'>  
                                    <TextArea 
                                        label='Configuration' 
                                        autoResize 
                                        placeholder='Please input the configuration here' 
                                        style={{ height: '500px', width: '100%' }} 
                                        value={payload} 
                                        onChange={(value: any) => setPayload(value)} 
                                        aria-label="text area example" 
                                    />  
                                </FormGroup>  
                            </FlexItem>  
                        </Flex>  
                    </PageSection>  
                </Page>}  
        </React.Fragment>  
    );  
}