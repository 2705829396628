import { Button, Flex, FlexItem, FormGroup, Modal, Page, PageSection, Spinner, TextArea, ToolbarContent, ToolbarItem } from '@patternfly/react-core';
import React, { useEffect } from 'react';
import { transformData } from './FlowService';

export function PreviewModal({ isModal, handleModalToggle, templateData, token }: { isModal: boolean, handleModalToggle: () => void, templateData: any, token: string }) {

    return (
        <React.Fragment>
            <Modal
                width={'75%'}
                title="Preview Data Transformation"
                isOpen={isModal}
                onClose={handleModalToggle}
                height={'50%'}
            >
                <Preview data={templateData} token={token} />
            </Modal>
        </React.Fragment>
    );
}

export function Preview({ data , token}: { data: any , token: string}) {
    // In real usage, this data would come from some external source like an API via props.
    const [isLoading, setIsLoading] = React.useState(false);
    const [payload, setPayload] = React.useState('');
    const [result, setResult] = React.useState('');
    useEffect(() => {
    }, []);

    return (
        <React.Fragment>
            {isLoading ? <Spinner aria-label="Contents of the basic example" /> :
                <Page>
                    <PageSection padding={{ default: 'noPadding' }} variant='light'>
                        <Flex>
                            <FlexItem align={{ default: 'alignRight' }}>
                                <ToolbarItem alignment={{ default: 'alignRight' }} variant="search-filter">
                                    <ToolbarContent alignment={{ default: 'alignRight' }}>
                                        <Button onClick={() => {
                                            setIsLoading(true);
                                            let parsedJson;
                                            try {
                                                parsedJson = JSON.parse(payload)
                                            }
                                            catch (e) {
                                                parsedJson = payload
                                            }
                                            let previewJson = {
                                                "name": data.templateName,
                                                "sourceId": data.sourceId,
                                                "sourceEventType": data.sourceEventType,
                                                "targetId": data.targetId,
                                                "targetEventType": data.targetEventType,
                                                "payloadData": parsedJson
                                            };
                                            transformData(previewJson,token).then((response: any) => {
                                                setIsLoading(false);
                                                let data;
                                                if (response.data.dataContentType === 'XML') {
                                                    let xmlDoc = new DOMParser().parseFromString(response.data.transformedData, 'application/xml');
                                                    let xsltDoc = new DOMParser().parseFromString([
                                                        // describes how we want to modify the XML - indent everything
                                                        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
                                                        '  <xsl:strip-space elements="*"/>',
                                                        '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
                                                        '    <xsl:value-of select="normalize-space(.)"/>',
                                                        '  </xsl:template>',
                                                        '  <xsl:template match="node()|@*">',
                                                        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
                                                        '  </xsl:template>',
                                                        '  <xsl:output indent="yes"/>',
                                                        '</xsl:stylesheet>',
                                                    ].join('\n'), 'application/xml');

                                                    let xsltProcessor = new XSLTProcessor();
                                                    xsltProcessor.importStylesheet(xsltDoc);
                                                    let resultDoc = xsltProcessor.transformToDocument(xmlDoc);
                                                    data = new XMLSerializer().serializeToString(resultDoc);
                                                }
                                                else {
                                                    try {
                                                        data = JSON.stringify(response.data, undefined, 4);
                                                    }
                                                    catch (e) {
                                                        data = response.data;
                                                    }
                                                }
                                                if (response.success) {
                                                    setResult(data);
                                                }
                                                else {
                                                    setResult(response.message);
                                                }
                                            })
                                        }}>Test</Button>
                                    </ToolbarContent>
                                </ToolbarItem>
                            </FlexItem>
                        </Flex>
                    </PageSection>
                    <PageSection isFilled padding={{ default: 'noPadding' }} variant='light'>
                        <Flex>
                            <FlexItem style={{ flex: 'auto' }}>
                                <FormGroup label="Sample payload data" fieldId='sample-payload-textarea'>
                                    <TextArea label='Sample payload data' autoResize placeholder='Please input the sample payload here' style={{ height: '500px', width: '100%' }} value={payload} onChange={(value: any) => { setPayload(value) }} aria-label="text area example" />
                                </FormGroup>
                            </FlexItem>
                            <FlexItem style={{ flex: 'auto' }}>
                                <FormGroup label="Transformation response" fieldId='transformation-response-textarea'>
                                    <TextArea label='Transformation response' autoResize readOnly={true} placeholder='Transformation response' style={{ height: '500px', width: '100%' }} value={result} aria-label="text area example" />
                                </FormGroup>
                            </FlexItem>
                        </Flex>
                    </PageSection>
                </Page>}
        </React.Fragment>
    );
}
