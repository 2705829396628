import { Label, Modal, Spinner } from '@patternfly/react-core';
import { CogIcon, MinusCircleIcon, PenIcon, PlusCircleIcon, UserIcon } from '@patternfly/react-icons';
import { Table, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import React, { useEffect } from 'react';
import { fetchAudit } from './FlowService';

export function AuditLog({ isModal, handleModalToggle, token }: { isModal: boolean, handleModalToggle: () => void, token: string}) {

    return (
        <React.Fragment>
            <Modal
                width={'75%'}
                title="Audit Logs"
                isOpen={isModal}
                onClose={handleModalToggle}
                height={'50%'}
            >
                <TableBasic token={token} />
            </Modal>
        </React.Fragment>
    );
}

export const TableBasic: React.FunctionComponent<{ token: string }> = ({ token }) => {
    // In real usage, this data would come from some external source like an API via props.
    const [auditLogs, setAuditLogs] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    useEffect(() => {
        fetchAudit(token)
            .then(function (response) {
                setAuditLogs(response.data);
                setIsLoading(false);
            }).catch(function (e) {
                console.log(e);
                setAuditLogs([]);
            });
    }, []);

    const columnNames = {
        id: 'Id',
        actionBy: 'User',
        entityClassName: 'Entity Class Name',
        message: 'Message',
        actionType: 'Action Type',
        tenantId: 'Tenant',
        timestamp: 'Timestamp',
    };

    return (
        <React.Fragment>
            {isLoading ? <Spinner aria-label="Contents of the basic example" /> : <Table
                aria-label="Simple table"
                variant='compact'
                borders={true}
            >
                <Thead>
                    <Tr>
                        <Th>{columnNames.tenantId}</Th>
                        <Th>{columnNames.entityClassName}</Th>
                        <Th>{columnNames.actionBy}</Th>
                        <Th>{columnNames.actionType}</Th>
                        <Th>{columnNames.message}</Th>
                        <Th>{columnNames.timestamp}</Th>
                    </Tr>
                </Thead>
                <Tbody style={{ overflowY: 'scroll' }}>
                    {auditLogs?.map((repo) => (
                        <Tr key={repo.id}>
                            <Td dataLabel={columnNames.tenantId}>{repo.tenantId}</Td>
                            <Td dataLabel={columnNames.entityClassName}>{repo.entityClassName}</Td>
                            <Td dataLabel={columnNames.actionBy}>{repo.actionBy ? <Label color="cyan" icon={<UserIcon />}>{repo.actionBy}</Label> : <Label icon={<CogIcon />}>{'System'}</Label>} </Td>
                            <Td dataLabel={columnNames.actionType} style={{ color: repo.actionType === 'CREATED' ? 'green' : repo.actionType === 'UPDATED' ? 'yellow' : 'red' }}>
                                {repo.actionType === 'CREATED' ?
                                    <Label variant="outline" color="cyan" icon={<PlusCircleIcon />}> {repo.actionType}</Label> :
                                    repo.actionType === 'UPDATED' ?
                                        <Label variant="outline" color="blue" icon={<PenIcon />}> {repo.actionType}</Label> :
                                        <Label variant="outline" color="red" icon={<MinusCircleIcon />}> {repo.actionType}</Label>}</Td>
                            <Td dataLabel={columnNames.message}>{repo.message}</Td>
                            <Td dataLabel={columnNames.timestamp}>{new Date(repo.timestamp).toLocaleString(navigator.language)}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>}
        </React.Fragment>
    );
};
