/*
    Copyright (C) 2017 Red Hat, Inc.

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

            http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
*/
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { AtlasmapProvider } from '@atlasmap/atlasmap';
import React from 'react';
import ReactDOM from 'react-dom';

//fetch token from url query parameter;
let search = window.location.search.replace('?data=', ''); 
let convertedData = search ? atob(search) : ''
let query: { [key: string]: string } = {};  
if (convertedData) {  
  let params = new URLSearchParams(decodeURI(convertedData));  
  let entries = Array.from(params); // Convert to an array  
  entries.forEach(pair => {  
    query[pair[0]] = pair[1].replace(/"/g, '\\"');  
  });  
}  
 
//fetch token from query parameter
let token = query.token;

if (!token) {
  alert('Unauthorized: Token not found');
  throw new Error('Unauthorized');
}

fetch('v2/atlas/check-token', {    
  headers: {    
    'Authorization': `Bearer ${token}`    
  }    
})    
.then(response => {    
  if (response.status === 401) {    
    alert('Unauthorized: Invalid token');    
    throw new Error('Unauthorized');    
  }    
  if (!response.ok) {    
    alert('Unauthorized: Invalid token');    
    throw new Error('Token check failed');    
  }    
  ReactDOM.render(    
    <AtlasmapProvider    
      baseJavaInspectionServiceUrl={'/v2/atlas/java/'}    
      baseXMLInspectionServiceUrl={'/v2/atlas/xml/'}    
      baseJSONInspectionServiceUrl={'/v2/atlas/json/'}    
      baseCSVInspectionServiceUrl={'/v2/atlas/csv/'}    
      baseMappingServiceUrl={'/v2/atlas/'}    
      logLevel={'info'}    
    >    
      <App />    
    </AtlasmapProvider>,    
    document.getElementById('root'),    
  );    
})    
.catch(error => {    
  // Handle the error  
  console.error('Error:', error); 
  throw new Error('Unauthorized');   
}); 



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
