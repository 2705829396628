export const FLOW_SERVICE_BASE_URL = process.env.REACT_APP_FLOW_SERVICE_BASE_URL || 'https://dev-transformation.apteansharedservices.com/api/v1/';
export const AIPDATA_BASE_URL = process.env.REACT_APP_AIPDATA_BASE_URL || 'https://dev.integration-graph.apteansharedservices.com';
export const FLOW_HEADERS = {
    'x-aptean-tenant': process.env.REACT_APP_FLOW_HEADERS_X_APTEAN_TENANT || '',
    'X-APTEAN-APIM': process.env.REACT_APP_FLOW_HEADERS_X_APTEAN_APIM || '6586b170cec14b41a2fd259ff23cb07a',
    'X-APTEAN-PRODUCT': process.env.REACT_APP_FLOW_HEADERS_X_APTEAN_PRODUCT || '7af17029-c37d-4945-ac5f-88d72651fc9b',
    'x-aptean-correlation-id': process.env.REACT_APP_FLOW_HEADERS_X_APTEAN_CORRELATION_ID || '19492c71-f04b-438e-9bb9-850757d404aa',
    'x-aptean-user': process.env.REACT_APP_FLOW_HEADERS_X_APTEAN_USER || 'aip-systemadmin'
};
export const AIP_HEADERS = {
    'X-APTEAN-TENANT': process.env.REACT_APP_AIP_HEADERS_X_APTEAN_TENANT || '769d550c-0ac3-4d4b-9231-a99527939b51',
    'X-APTEAN-APIM': process.env.REACT_APP_AIP_HEADERS_X_APTEAN_APIM || '6586b170cec14b41a2fd259ff23cb07a',
    'X-APTEAN-PRODUCT': process.env.REACT_APP_AIP_HEADERS_X_APTEAN_PRODUCT || '7af17029-c37d-4945-ac5f-88d72651fc9b',
    // 'x-aptean-correlation-id': '19492c71-f04b-438e-9bb9-850757d404aa',
    // 'x-aptean-user': 'ymishra'
};
